// TODO: refatorar o componente para usar o PixelCanvasComponent
// ao invés de implementar manulmente a lógica de renderização

import { Component, AfterViewInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as model from '@app/projeto.model';

@Component({
    selector: 'app-fontes-form-recurso',
    templateUrl: './fontes-form-recurso.component.html',
    styleUrls: ['./fontes-form-recurso.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class FontesFormRecursoComponent implements AfterViewInit, OnChanges {

    @Input() recurso: model.Recurso;

    // precisamos registrar as alterações na detecção do angular, e esse é um hackzinho simples pra isso
    @Input() x: number;
    @Input() y: number;

    @ViewChild('canvas') public canvas: ElementRef;
    private cx: CanvasRenderingContext2D;
    private circlesVar;

    public color: string = '#f2d21f';

    constructor() { }

    ngAfterViewInit() {
        const canvasEl = HTMLCanvasElement = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');

        this.setupCanvas();
    }

    ngOnChanges() {
        this.setupCanvas();
    }

    private setupCanvas() {
        if (this.cx !== undefined) {
            this.cx.canvas.width = this.recurso.largura * 20;
            this.cx.canvas.height = this.recurso.tamanho * 20;

            this.cx.canvas.addEventListener('mousedown', (event) => this.startDrawing(event));
            this.cx.canvas.addEventListener('mousemove', (event) => this.handlePaint(event));
            this.cx.canvas.addEventListener('mouseup', () => this.stopDrawing());
            this.cx.canvas.addEventListener('mouseleave', () => this.stopDrawing());

            this.circlesVar = this.circles();
            this.gerarPontos();
            this.draw();
        }
    }

    isDrawing = false;

    private startDrawing(event: MouseEvent) {
        this.isDrawing = true;
        this.handlePaint(event); // To start drawing immediately from the initial point
    }

    private handlePaint(event: MouseEvent) {
        if (this.isDrawing && this.recurso.rgb) {
            this.paintPoint(event.offsetX, event.offsetY);
        }
    }

    private stopDrawing() {
        this.isDrawing = false;
    }

    public registerClick(click: MouseEvent) {
        if (this.recurso.rgb) {
            this.paintPoint(click.offsetX, click.offsetY);
        } else {
            this.togglePoint(click.offsetX, click.offsetY);
        }
    }

    private paintPoint(x, y) {
        this.circlesVar.forEach((circle, index) => {
            if (this.isIntersect({ x, y }, circle)) {
                this.circlesVar[index].on = true;
                this.circlesVar[index].color = this.color;
                this.gerarPontos();
                this.draw();
            }
        });
    }

    private togglePoint(x, y) {
        this.circlesVar.forEach((circle, index) => {
            if (this.isIntersect({ x, y }, circle)) {
                this.circlesVar[index].on = !this.circlesVar[index].on;
                this.gerarPontos();
                this.draw();
            }
        });
    }


    public circles() {
        const pontos = [];
        for (let i = 0; i < this.recurso.largura; i++) {
            for (let j = 0; j < this.recurso.tamanho; j++) {
                let on = false;
                let color = '#f2d21f';

                let ponto = this.inPoints(i, j);
                console.log(ponto);
                if (ponto) {
                    on = true;
                    if (ponto.color) {
                        color = ponto.color;
                    }
                }

                pontos.push({
                    'x': (i * 20 + 8),
                    'y': (j * 20 + 8),
                    'radius': 8,
                    'on': on,
                    'color': color,
                });
            }
        }
        return pontos;
    }

    public draw(): void {
        let pontos = this.circlesVar;
        this.cx.clearRect(0, 0, this.cx.canvas.width, this.cx.canvas.height); // limpar o canvas
        pontos.forEach((ponto) => {
            this.cx.beginPath();
            this.cx.arc(ponto.x, ponto.y, ponto.radius, 0, 2 * Math.PI, false);
            if (ponto.on && this.recurso.rgb && ponto.color) {
                this.cx.fillStyle = ponto.color;
            } else if (ponto.on) {
                this.cx.fillStyle = '#f2d21f';
            } else {
                this.cx.fillStyle = '#333';
            }
            this.cx.fill();

        });
    }

    private gerarPontos() {
        this.recurso.pontos = [];
        this.circlesVar.forEach((circle) => {
            const realX = (circle.x - 8) / 20 + 1;
            const realY = (circle.y - 8) / 20 + 1;
            if (circle.on && realX > 0 && realX <= this.recurso.largura && realY > 0 && realY <= this.recurso.tamanho) {
                this.recurso.pontos.push({
                    'x': realX,
                    'y': realY,
                    'color': circle.color,
                });
            }
        });
    }

    private isIntersect(point, circle) {
        return Math.abs(point.x - circle.x) < 11 && Math.abs(point.y - circle.y) < 11;
    }
    private inPoints(x: number, y: number): model.Ponto | undefined {
        for (let ponto of this.recurso.pontos) {
            if (+ponto.x - 1 === +x && +ponto.y - 1 === +y) {
                return ponto;
            }
        }
    }

    alterarCor(event: string) {
        this.color = event;
    }

    toggleRgb(): void {
        this.recurso.rgb = !this.recurso.rgb;
        this.draw();
    }
}
