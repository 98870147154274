import { Injectable, OnInit } from '@angular/core';
import * as model from '../projeto.model';
import { DesenhosApiService } from './desenhos-api.service';
import { FontesApiService } from '@app/services/fontes-api.service';

@Injectable({
    providedIn: 'root'
})
export class TranslateResourcesService implements OnInit {
    public desenhos;

    constructor(
        private desenhosApi: DesenhosApiService,
        private fontesApi: FontesApiService,
    ) { }

    async ngOnInit() {
        this.desenhos = await this.desenhosApi.list_all();
    }

    public async translate(grupo: model.Grupo, strict = true) {
        const letras = await this.getPoints(grupo);

        const { pontos_ligados } = this.translatePoints(grupo, letras, strict);

        pontos_ligados.forEach(ponto => {
            ponto.x += grupo.start_x - 1;
            ponto.y += grupo.start_y - 1;
        })

        return pontos_ligados;
    }

    private translatePoints(grupo: model.Grupo, letras: model.Ponto[][], strict = true): TranslatedGroup {
        const pontos_ligados = [];

        let coluna_atual = 0;

        letras.forEach((letra: model.Ponto[], letraIndex: number) => {
            let spacing = grupo.spacing;
            if (letraIndex == 0) {
                spacing = 0;
            }

            if (letra.length === 0) { // é espaço
                // Obs: O espaçamento entre caracteres também é
                // aplicado em letras que vem depois do espaço
                coluna_atual += 2 + spacing;
                return;
            }

            // Deslocamento do próximo caractere que será desenhado
            // Precisamos dessa variável aqui pois vamos modificar a coluna_atual
            let deslocamento_x = coluna_atual + spacing;

            // Clonar os pontos para não modificar a fonte acidentalmente
            let pontos = letra.map(pontos => Object.assign({}, pontos));

            pontos.forEach((point: model.Ponto) => {
                point.x += deslocamento_x;
                if (point.x > coluna_atual) {
                    coluna_atual = point.x;
                }

                // Aplicar o deslocamento dos efeitos no simulador, normalmente não haverá deslocamento
                point.x += +grupo.offsetSimX || 0;
                point.y += +grupo.offsetSimY || 0;

                point.x += grupo.offsetX;
                point.y += grupo.offsetY;

                // Cortar pontos fora dos limites do grupo
                const underflow_x = (+point.x + grupo.start_x) <= +grupo.start_x;
                const underflow_y = (+point.y + grupo.start_y) <= +grupo.start_y;
                const overflow_x = (+grupo.end_x + 1) < (+point.x + grupo.start_x);
                const overflow_y = (+grupo.end_y + 1) < (+point.y + grupo.start_y);
                if (strict && (underflow_x || underflow_y || overflow_x || overflow_y)) {
                    return;
                }

                pontos_ligados.push(point);
            });
        });

        return { pontos_ligados, coluna_atual };
    }

    private async getPoints(grupo: model.Grupo): Promise<model.Ponto[][]> {
        const desenhos = (await this.desenhosApi.list_all());
        const desenho: model.Desenho = desenhos.find(
            (desenho: model.Desenho) =>
                +desenho.origem == +grupo.origem
                && +desenho.id == +grupo.idOrigem
        );

        if (!desenho) {
            console.error("Grupo com desenho invalido", grupo, desenho);
            throw Error("Grupo com desenho invalido");
        }

        if (+ desenho.origem === 1) { // Fonte
            let fonte = await this.fontesApi.get_by_id(desenho.getFonteId());
            return desenho.getPontos(grupo.content, fonte.letras);
        } else if (+desenho.origem === 2) { // Recurso
            return desenho.getPontos(grupo.content, []);
        } else if (+desenho.origem === 3) { // Variável
            let fonte = await this.fontesApi.get_by_id(desenho.getFonteId());
            // TODO: o objeto variavel percisa ter acesso a fonte para para poder pegar os pontos
            // talvez seja melhor mover essa lógica para um serviço separado.
            let variavel = desenho as model.Variavel;
            variavel.fonte = await this.fontesApi.get_by_id(variavel.getFonteId());
            return variavel.getPontos(grupo.content, fonte.letras);
        }

        console.error("Desenho com origem invalida", desenho);
    }

    public async getAutoSize(grupo: model.Grupo) {
        const letras = await this.getPoints(grupo);

        let { pontos_ligados, coluna_atual } = this.translatePoints(grupo, letras, false);
        let linha_atual = Math.max(1, ...pontos_ligados.map(p => p.y));

        if (letras.length == 0) {
            coluna_atual = 1;
        }

        const tamanhos = [grupo.start_x + coluna_atual, grupo.start_y + linha_atual];
        console.log("autoSize = ", tamanhos);

        return [grupo.start_x + grupo.offsetX + coluna_atual - 1, grupo.start_y + linha_atual - 1];
    }
}

interface TranslatedGroup {
    pontos_ligados: model.Ponto[],
    coluna_atual: number
}
