import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as model from '@app/projeto.model';

export class Retangulo {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    selected: boolean;
}

export class Click {
    x: number;
    y: number;
}

const PIXEL_SIZE = 20;

@Component({
    selector: 'app-pixel-canvas',
    templateUrl: './pixel-canvas.component.html',
    styleUrls: ['./pixel-canvas.component.css'],

})
export class PixelCanvas implements AfterViewInit, OnDestroy {

    @Input() selected: boolean = false;

    @Input() width: number = 1;
    @Input() height: number = 1;
    @Input() pontos: model.Ponto[] = [];

    @Input() retangulos: Retangulo[] = [];

    @Output() click = new EventEmitter<Click>();

    private redrawInterval: ReturnType<typeof setInterval> | undefined;
    private dashOffset = 0;

    @ViewChild('canvas') public canvas: ElementRef;
    private cx: CanvasRenderingContext2D;

    public constructor(private cdr: ChangeDetectorRef) {
    }

    public ngAfterViewInit() {
        // await this.makePoints();

        // remove o componente da árvore de detecção
        // isso melhora imensamente a performance da aplicação,
        // pois o angular não precisa recarregar o canvas a cada ciclo de updates
        this.cdr.detach();

        const canvasEl = HTMLCanvasElement = this.canvas.nativeElement;

        this.cx = canvasEl.getContext('2d');

        this.draw();

        this.redrawInterval = setInterval(() => {
            this.draw();
            this.updateFlicker();
        }, 32);
    }

    public ngOnDestroy() {
        this.stopFlicker();
    }

    public update() {
        // cada vez que essa função for chamada, ele vai detectar as mudanças
        // e rerenderizar tudo
        if (this.cx !== undefined) {
            this.cdr.detectChanges();
            // await this.makePoints();
            this.draw(); // desenhar depois de detectar as mudanças e de refazer os pontos
        }
    }

    private point_drawing_cache = {};
    private getCircleWithColor(color: string): CanvasImageSource {
        if (this.point_drawing_cache[color])
            return this.point_drawing_cache[color];

        console.log("Circle with color", color);

        var texture = document.createElement('canvas');
        texture.width = 20;
        texture.height = 20;
        var ctx = texture.getContext('2d');
        ctx.fillStyle = color;
        ctx.arc(10, 10, 8, 0, 2 * Math.PI, false);
        ctx.fill();

        this.point_drawing_cache[color] = texture;

        return texture;
    }

    private empty_canvas_cache = {
        width: 0,
        height: 0,
        canvas: undefined,
    };
    private drawEmptyCanvas(): CanvasImageSource {
        if (this.empty_canvas_cache.width == this.canvas.nativeElement.width &&
            this.empty_canvas_cache.height == this.canvas.nativeElement.height) {
            return this.empty_canvas_cache.canvas;
        }
        console.log("Init empty canvas texture");

        var off = document.createElement('canvas');
        off.width = this.canvas.nativeElement.width;
        off.height = this.canvas.nativeElement.height;
        var ctx = off.getContext('2d');

        ctx.fillStyle = '#000000';
        ctx.clearRect(0, 0, off.width, off.height); // limpar o canvas

        for (let x = 0; x < this.width; x++) {
            for (let y = 0; y < this.height; y++) {
                let texture = this.getCircleWithColor('#333');
                ctx.drawImage(texture, +x * PIXEL_SIZE, +y * PIXEL_SIZE);
            }
        }

        this.empty_canvas_cache = {
            width: off.width,
            height: off.height,
            canvas: off,
        };

        return off;
    }

    public draw(): void {
        this.cx.canvas.width = this.width * PIXEL_SIZE;
        this.cx.canvas.height = this.height * PIXEL_SIZE;

        this.cx.clearRect(0, 0, this.cx.canvas.width, this.cx.canvas.height); // limpar o canvas

        // let enabled_points: boolean[][] = Array(this.width);
        // for (let x = 0; x < this.width; x++) {
        //     enabled_points[x] = Array(this.height);
        //     for (let y = 0; y < this.height; y++) {
        //         enabled_points[x][y] = false;
        //     }
        // }

        // this.pontos.forEach((ponto) => {
        //     enabled_points[ponto.x][ponto.y] = true;
        // });

        let empty_canvas = this.drawEmptyCanvas();
        this.cx.drawImage(empty_canvas, 0, 0);

        this.pontos.forEach(ponto => {
            const x = ponto.x - 1;
            const y = ponto.y - 1;

            let texture = this.getCircleWithColor(ponto.color || '#f2d21f');
            this.cx.drawImage(texture, +x * PIXEL_SIZE, +y * PIXEL_SIZE);
        });

        this.retangulos.forEach(retangulo => {
            if (retangulo.selected) {
                return;
            }

            this.cx.beginPath();
            this.cx.lineWidth = 4;
            this.cx.strokeStyle = 'red';

            const x1 = (retangulo.x1 - 1) * PIXEL_SIZE;
            const x2 = (retangulo.x2 - 1) * PIXEL_SIZE;

            const y1 = (retangulo.y1 - 1) * PIXEL_SIZE;
            const y2 = (retangulo.y2 - 1) * PIXEL_SIZE;

            this.cx.rect(x1, y1, (x2 - x1), (y2 - y1));
            this.cx.stroke();
        });
    }

    public handleClick(click: MouseEvent) {

        console.log('event', {
            x: click.offsetX,
            y: click.offsetY
        });

        const nativeElement = this.canvas.nativeElement;

        const propX = click.offsetX / nativeElement.offsetWidth;
        const propY = click.offsetY / nativeElement.offsetHeight;

        console.log('prop', {
            x: propX,
            y: propY,
        });

        const pointX = propX * this.width;
        const pointY = propY * this.height;

        console.log('point', {
            x: pointX,
            y: pointY,
        });

        this.click.emit({
            x: Math.ceil(pointX),
            y: Math.ceil(pointY),
        });
    }

    public updateFlicker() {
        this.retangulos.forEach(retangulo => {
            if (!retangulo.selected) {
                return;
            }

            const x1 = (retangulo.x1 - 1) * PIXEL_SIZE;
            const x2 = (retangulo.x2 - 1) * PIXEL_SIZE;

            const y1 = (retangulo.y1 - 1) * PIXEL_SIZE;
            const y2 = (retangulo.y2 - 1) * PIXEL_SIZE;

            this.cx.beginPath();

            this.cx.strokeStyle = 'black';
            this.cx.lineWidth = 4;
            this.cx.strokeRect(x1, y1, (x2 - x1), (y2 - y1));

            this.cx.setLineDash([10, 10]);
            this.cx.lineDashOffset = -this.dashOffset;
            this.cx.strokeStyle = 'red';

            this.cx.strokeRect(x1, y1, (x2 - x1), (y2 - y1));
            this.cx.setLineDash([]);

            this.dashOffset += 2;
            if (this.dashOffset > 256) {
                this.dashOffset = 0;
            }
        });
    }

    public stopFlicker() {
        if (this.redrawInterval) {
            clearInterval(this.redrawInterval);
        }
        this.draw();
    }

}
