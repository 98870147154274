// TODO: refatorar o componente para usar o PixelCanvasComponent
// ao invés de implementar manulmente a lógica de renderização

import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, OnChanges } from '@angular/core';
import * as model from '@app/projeto.model';

@Component({
    selector: 'app-letra',
    templateUrl: './letra.component.html',
    styleUrls: ['./letra.component.scss']
})
export class LetraComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() letra: model.Letra;
    @Input() canvasX: number;
    @Input() canvasY: number;
    @Input() i: number;
    @Input() fonte: model.Fonte;

    @Output() excluir$ = new EventEmitter();
    @Output() duplicar$ = new EventEmitter();

    @ViewChild('canvas') public canvas: ElementRef;
    private cx: CanvasRenderingContext2D;
    private circlesVar;

    constructor() {
    }

    ngOnInit() {
        this.letra.isEmpty = false;
        this.letra.isEmpty = false;
    }

    ngAfterViewInit() {
        const canvasEl = HTMLCanvasElement = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');

        this.setupCanvas();
    }

    ngOnChanges() {
        this.setupCanvas();
    }

    private setupCanvas() {
        if (this.cx !== undefined) {
            this.cx.canvas.width = this.canvasX * 20;
            this.cx.canvas.height = this.canvasY * 20;

            this.circlesVar = this.circles();
            this.gerarPontos();
            this.draw(this.circlesVar);
        }
    }

    public excluir() {
        this.excluir$.emit(this.letra);
    }

    public duplicar() {
        this.duplicar$.emit();
    }

    public registerClick(click: MouseEvent) {
        const pos = {
            x: click.offsetX,
            y: click.offsetY
        };
        this.circlesVar.forEach((circle, index) => {
            if (this.isIntersect(pos, circle)) {
                this.circlesVar[index].on = !this.circlesVar[index].on;
                this.gerarPontos();
                this.draw(this.circlesVar);
            }
        });
    }


    public circles() {
        const pontos = [];
        for (let i = 0; i < this.canvasX; i++) {
            for (let j = 0; j < this.canvasY; j++) {
                let on = false;

                if (this.inPoints(i, j)) {
                    on = true;
                }

                pontos.push({
                    'x': (i * 20 + 8),
                    'y': (j * 20 + 8),
                    'radius': 8,
                    'on': on
                });
            }
        }
        return pontos;
    }

    public draw(pontos): void {
        this.cx.clearRect(0, 0, this.cx.canvas.width, this.cx.canvas.height); // limpar o canvas
        pontos.forEach((ponto) => {
            this.cx.beginPath();
            this.cx.arc(ponto.x, ponto.y, ponto.radius, 0, 2 * Math.PI, false);
            this.cx.fillStyle = ponto.on ? '#f2d21f' : '#333';
            this.cx.fill();

        });
    }

    private gerarPontos() {
        this.letra.pontos = [];
        this.circlesVar.forEach((circle) => {
            const realX = (circle.x - 8) / 20 + 1;
            const realY = (circle.y - 8) / 20 + 1;
            if (circle.on && realX > 0 && realX <= this.canvasX && realY > 0 && realY <= this.canvasY) {
                this.letra.pontos.push({
                    'x': realX,
                    'y': realY,
                });
            }
        });
    }

    private isIntersect(point, circle) {
        return Math.sqrt((point.x - circle.x) ** 2 + (point.y - circle.y) ** 2) < circle.radius;
    }
    private inPoints(x: number, y: number): boolean {
        let inPoint = false;

        this.letra.pontos.forEach((ponto) => {
            if (+ponto.x - 1 === +x && +ponto.y - 1 === +y) {
                inPoint = true;
            }
        });

        return inPoint;
    }

    isDuplicatedCharacter (letra: model.Letra, fonte: model.Fonte): boolean {
        let equalLetters = fonte.letras.filter(letter => letter.caractere == letra.caractere);
        letra.isDuplicated = (equalLetters.length !== 1);
        return letra.isDuplicated;
    }

    isCharacterEmpty(letra: model.Letra,): boolean {
        letra.isEmpty = (letra.caractere.trim() === '');
        return letra.isEmpty;
    }
}
