import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Recurso, RecursoUpload } from "@app/projeto.model";

import { HelpService } from "@app/services/help.service";

import { RecursosApiService } from "@app/services/recursos-api.service";

@Component({
    selector: "app-recursos-upload-page",
    templateUrl: "./recursos-upload-page.component.html",
    styleUrls: ["./recursos-upload-page.component.scss"],
})
export class RecursosUploadPageComponent {
    public submitting = false;

    public aspectRatio = 1.0;

    public maxWidth = 192;
    public maxHeight = 32;

    // Max size for the currently uploaded image
    // Example:
    // An image is 10x5, and the maximum size is 20x20
    // The scaled max size would be 20x10
    public scaledMaxWidth = 192;
    public scaledMaxHeight = 32;

    public upload: RecursoUpload = {
        width: 10,
        height: 10,

        color_type: 'mono',
        image: null,
        inverted: false,
    };

    public recurso: Recurso = null;

    constructor(
        public recursosApi: RecursosApiService,
        private router: Router,
        private titleService: Title,
        private help: HelpService
    ) {
        this.help.local = "recursos";
        this.titleService.setTitle(
            $localize`Adicionar recurso - Thoreb Itinerário`
        );
    }

    async onChangeColorType(event: Event) {
        let value = (event.target as HTMLInputElement).value;
        if (value != 'rgb' && value != 'mono') {
            console.error(value);
            throw new Error("Tipo de cor inválido?????");
        }

        this.upload.color_type = value;

        this.update_preview();
    }

    async onChangeImage(value: string | File) {
        if (!(value instanceof File)) {
            console.error(value);
            throw new Error("Imagem inválida?????");
        }
        this.upload.image = value;

        await this.sizeFromUploadedImage();

        this.update_preview();
    }

    onChangeWidth(event: Event) {
        this.upload.width = +(event.target as HTMLInputElement).value;
        this.upload.height = Math.round(this.upload.width / this.aspectRatio);
        this.update_size();
        this.update_preview();
    }

    onChangeHeight(event: Event) {
        this.upload.height = +(event.target as HTMLInputElement).value;
        this.upload.width = Math.round(this.upload.height * this.aspectRatio);
        this.update_size();
        this.update_preview();
    }

    scaled_down(
        width: number,
        height: number,
        max_width: number,
        max_height: number,
    ): { width: number, height: number } {
        const scaleWidth = width / max_width;
        const scaleHeight = height / max_height;

        const maxScale = Math.max(scaleWidth, scaleHeight);

        if (maxScale > 1) {
            width /= maxScale;
            height /= maxScale;
        }

        return {
            width: Math.round(width),
            height: Math.round(height),
        }
    }

    update_size() {
        if (this.upload.width <= 1)
            this.upload.width = 1;
        if (this.upload.height <= 1)
            this.upload.height = 1;

        const newSize = this.scaled_down(
            this.upload.width,
            this.upload.height,
            this.maxWidth,
            this.maxHeight,
        );

        this.upload.width = newSize.width;
        this.upload.height = newSize.height;
    }

    async sizeFromUploadedImage() {
        const arrayBuffer = this.upload.image;
        if (arrayBuffer == null) {
            console.warn('guessSize called without arrayBuffer');
            return;
        }

        const blob = new Blob([arrayBuffer]);
        const url = URL.createObjectURL(blob);

        const img = new Image();
        const promise: Promise<{ width: number, height: number }> = new Promise(resolve => {
            img.onload = () => {
                resolve({
                    width: img.width,
                    height: img.height,
                });
            };
        });
        img.src = url;

        const { width, height } = await promise;

        URL.revokeObjectURL(url);

        this.upload.width = width;
        this.upload.height = height;

        this.aspectRatio = width / height;

        this.update_scaled_max_sizes(this.aspectRatio);

        this.update_size();
    }

    update_scaled_max_sizes(aspectRatio: number) {
        const width = 9999;
        const height = width / aspectRatio;

        const scaledMax = this.scaled_down(
            width,
            height,
            this.maxWidth,
            this.maxHeight,
        );

        this.scaledMaxWidth = scaledMax.width;
        this.scaledMaxHeight = scaledMax.height;
    }

    onChangeInverted(event: Event) {
        let value = (event.target as HTMLInputElement).checked;
        this.upload.inverted = value;
        this.update_preview();
    }

    async update_preview() {
        console.log('update preview', this.upload);
        if (this.upload.color_type && this.upload.image) {
            this.recurso = await this.recursosApi.preview(this.upload);
        }
    }

    async submit() {
        try {
            this.submitting = true;

            await this.recursosApi.upload(this.upload);
            this.router.navigate(["recursos"]);
        } catch (e) {
            console.error(e);
            this.submitting = false;
        }
    }
}
