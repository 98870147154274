import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, TRANSLATIONS, LOCALE_ID, TRANSLATIONS_FORMAT } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TimesPipe } from './times-pipe';
import { NotEqualMultipleDirective } from './notEqualMultiple.directive';
import { containedInOptionsDirective } from './containedInOptions.directive';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxMaskModule } from 'ngx-mask';

import { AppComponent } from './app.component';

import { ProjetosListaComponent } from './projetos/projetos-lista/projetos-lista.component';
import { RoteirosListaComponent } from './roteiros/roteiros-lista/roteiros-lista.component';
import { PaineisListaComponent } from './paineis-lista/paineis-lista.component';
import { HomeComponent } from './home/home.component';

import { RoutingModule } from './routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { MenuAsideComponent } from './menu-aside/menu-aside.component';
import { NavComponent } from './nav/nav.component';
import { ProjetosFormComponent } from './projetos/projetos-form/projetos-form.component';
import { ProjetosCadastrarComponent } from './projetos/projetos-cadastrar/projetos-cadastrar.component';
import { ProjetosImportarComponent } from './projetos/projetos-importar/projetos-importar.component';
import { ProjetosEditarComponent } from './projetos/projetos-editar/projetos-editar.component';
import { RoteirosFormComponent } from './roteiros/roteiros-form/roteiros-form.component';
import { RoteirosCadastrarComponent } from './roteiros/roteiros-cadastrar/roteiros-cadastrar.component';
import { FontesPageComponent } from './fontes/fontes-page/fontes-page.component';
// import { FontesFormRecursoComponent } from './fontes/fontes-form/fontes-form-recurso/fontes-form-recurso.component';
// import { FontesFormFonteComponent } from './fontes/fontes-form/fontes-form-fonte/fontes-form-fonte.component';
// import { LetraComponent } from './fontes/fontes-form/fontes-form-fonte/letra/letra.component';
// import { LetraDeletadaComponent } from "./fontes/fontes-form/fontes-form-fonte/letra-deletada/letra-deletada.component";
import { LandingComponent } from './landing/landing.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { HelpLandingComponent } from './help-modal/help-landing/help-landing.component';
import { HelpHomeComponent } from './help-modal/help-home/help-home.component';
import { HelpEditorComponent } from './help-modal/help-editor/help-editor.component';
import { HelpProjetosComponent } from './help-modal/help-projetos/help-projetos.component';
import { HelpRoteirosComponent } from './help-modal/help-roteiros/help-roteiros.component';
import { TutorialComponent } from './tutorial/tutorial.component';

import { EditorModule } from './editor/editor.module';
import { SimuladorComponent } from './simulador/simulador.component';
import { HelpSimuladorComponent } from './help-modal/help-simulador/help-simulador.component';
import { LoginComponent } from './login/login.component';

import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { VersionCheckInterceptor } from './interceptors/version_check.interceptor';

import { EmpresaComponent } from './empresa/empresa.component';
import { UsuarioCadastrarComponent } from './usuario/usuario-cadastrar/usuario-cadastrar.component';
import { UsuarioEditarComponent } from './usuario/usuario-editar/usuario-editar.component';
import { UsuarioFormComponent } from './usuario/usuario-form/usuario-form.component';

import { TecnicoListaComponent } from './tecnico/tecnico-lista/tecnico-lista.component';
import { TecnicoCadastrarComponent } from './tecnico/tecnico-cadastrar/tecnico-cadastrar.component';
import { TecnicoEditarComponent } from './tecnico/tecnico-editar/tecnico-editar.component';
import { TecnicoFormComponent } from './tecnico/tecnico-form/tecnico-form.component';
import { TecnicoEditarSenhaComponent } from './tecnico/tecnico-editar-senha/tecnico-editar-senha.component';

import { AsyncEmailValidatorDirective } from './asyncEmail.directive';
import { CadastrarEmpresaComponent } from './empresa/cadastrar-empresa/cadastrar-empresa.component';
import { EmpresaFormComponent } from './empresa/empresa-form/empresa-form.component';
import { EmpresaListaComponent } from './empresa/empresa-lista/empresa-lista.component';
import { EditarEmpresaComponent } from './empresa/editar-empresa/editar-empresa.component';
import { ProjetosPainelFormComponent } from './projetos/projetos-painel-form/projetos-painel-form.component';
import { RecursosPageComponent } from './fontes/recursos-page/recursos-page.component';
import { RecursosEditarPageComponent } from './fontes/recursos-editar-page/recursos-editar-page.component';
import { RecursosCadastrarPageComponent } from './fontes/recursos-cadastrar-page/recursos-cadastrar-page.component';
import { HelpEmpresaComponent } from './help-modal/help-empresa/help-empresa.component';
import { HelpEmpresaUsuarioComponent } from './help-modal/help-empresa-usuario/help-empresa-usuario.component';
import { HelpRecursosComponent } from './help-modal/help-recursos/help-recursos.component';
import { HelpFontesComponent } from './help-modal/help-fontes/help-fontes.component';
import { HelpLoginComponent } from './help-modal/help-login/help-login.component';
import { HelpTutorialComponent } from './help-modal/help-tutorial/help-tutorial.component';
import { DownloaderComponent } from './suporte/downloader/downloader.component';
import { UsuarioEditarSenhaComponent } from './usuario/usuario-editar-senha/usuario-editar-senha.component';
import { EsqueciSenhaComponent } from './login/esqueci-senha/esqueci-senha.component';
import { ContatoComponent } from './contato/contato.component';
import { ContatoListaComponent } from './contato/contato-lista/contato-lista.component';
import { CNPJValidatorDirective } from './empresa/cnpj-validator.directive';
import { MustMatchDirective } from './shared/directives/must-match.directive';

import { FontesFormRecursoComponent } from './fontes/recursos-form/fontes-form-recurso.component';
import { FontesFormFonteComponent } from './fontes/fontes-form/fontes-form-fonte.component';
import { LetraComponent } from './fontes/fontes-form/letra/letra.component';
import { LetraDeletadaComponent } from './fontes/fontes-form/letra-deletada/letra-deletada.component';
import { LocaleSelectorComponent } from './locale-selector/locale-selector.component';
import { FileInputComponent } from './file-input/file-input.component';
import { RecursosUploadPageComponent } from './fontes/recursos-upload-page/recursos-upload-page.component';
import { PixelCanvas } from './shared/components/pixel-canvas/pixel-canvas.component';
import { PixelCanvasTestPageComponent } from './pixel-canvas-test-page/pixel-canvas-test-page.component';

declare const require; // Use the require method provid    ed by webpack

@NgModule({
    declarations: [
        AppComponent,
        TimesPipe,
        NotEqualMultipleDirective,
        containedInOptionsDirective,
        ProjetosListaComponent,
        RoteirosListaComponent,
        PaineisListaComponent,
        HomeComponent,
        MenuAsideComponent,
        NavComponent,
        ProjetosFormComponent,
        ProjetosCadastrarComponent,
        ProjetosImportarComponent,
        ProjetosEditarComponent,
        RoteirosFormComponent,
        RoteirosCadastrarComponent,
        FontesPageComponent,
        FontesFormRecursoComponent,
        FontesFormFonteComponent,
        LetraComponent,
        LetraDeletadaComponent,
        LandingComponent,
        HelpModalComponent,
        HelpLandingComponent,
        HelpHomeComponent,
        HelpEditorComponent,
        HelpProjetosComponent,
        HelpRoteirosComponent,
        TutorialComponent,
        SimuladorComponent,
        HelpSimuladorComponent,
        LoginComponent,
        EmpresaComponent,
        UsuarioCadastrarComponent,
        UsuarioEditarComponent,
        UsuarioFormComponent,

        TecnicoListaComponent,
        TecnicoCadastrarComponent,
        TecnicoEditarComponent,
        TecnicoFormComponent,
        TecnicoEditarSenhaComponent,

        AsyncEmailValidatorDirective,
        CadastrarEmpresaComponent,
        EmpresaFormComponent,
        EmpresaListaComponent,
        EditarEmpresaComponent,
        ProjetosPainelFormComponent,
        RecursosPageComponent,
        RecursosEditarPageComponent,
        RecursosCadastrarPageComponent,
        RecursosUploadPageComponent,
        HelpEmpresaComponent,
        HelpEmpresaUsuarioComponent,
        HelpRecursosComponent,
        HelpFontesComponent,
        HelpLoginComponent,
        HelpTutorialComponent,
        DownloaderComponent,
        UsuarioEditarSenhaComponent,
        EsqueciSenhaComponent,
        ContatoComponent,
        ContatoListaComponent,
        CNPJValidatorDirective,
        MustMatchDirective,
        LocaleSelectorComponent,
        PixelCanvas,
        PixelCanvasTestPageComponent,
    ],
    imports: [
        FileInputComponent,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RoutingModule,
        ToastrModule.forRoot({
            'positionClass': 'toast-bottom-right',
            'preventDuplicates': true
        }),
        EditorModule,
        QRCodeModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        Title,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: VersionCheckInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
