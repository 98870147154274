import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-file-input',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './file-input.component.html',
    styleUrl: './file-input.component.scss'
})
export class FileInputComponent {
    @Input() public mode: 'string' | 'arrayBuffer';

    public file_name: string;

    public file: File;

    private reader = new FileReader();

    @Output() onChange = new EventEmitter<File | string>();

    onFileSelected(event: Event) {
        this.file = (event.target as HTMLInputElement)?.files[0];

        if (!this.file) {
            return;
        }

        this.file_name = this.file.name;

        const reader = this.reader;
        reader.onload = () => {
            this.onFileLoaded();
        };

        if (this.mode == 'arrayBuffer') {
            reader.readAsArrayBuffer(this.file);
        } else if (this.mode == 'string') {
            reader.readAsText(this.file, 'UTF-8');
        } else {
            console.error("No file input type set", this.mode);
        }
    }

    onFileLoaded() {
        if (
            this.mode == 'arrayBuffer' &&
            this.file instanceof File
        ) {
            this.onChange.emit(this.file);
        } else if (
            this.mode == 'string' &&
            (typeof this.file) == 'string'
        ) {
            this.onChange.emit(this.file);
        } else {
            console.error("Unexpected output from file input", this.file, typeof this.file, this.mode);
        }
    }
}

