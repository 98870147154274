import { Component, OnInit } from "@angular/core";
import { ProjetoApiService } from "../services/projeto-api.service";

import { RoteiroApiService } from "@app/services/roteiro-api.service";
import { Ponto } from "@app/projeto.model";
import { PainelApiService } from "@app/services/painel-api.service";
import { Click, Retangulo } from "@app/shared/components/pixel-canvas/pixel-canvas.component";

@Component({
    selector: "app-pixel-canvas-test-page",
    templateUrl: "./pixel-canvas-test-page.component.html",
    styleUrls: ["./pixel-canvas-test-page.component.scss"],
})
export class PixelCanvasTestPageComponent implements OnInit {
    public test_points: Ponto[];
    public test_rectangles: Retangulo[];

    constructor(
        public projetoApi: ProjetoApiService,
        public roteiroApi: RoteiroApiService,
        public painelApi: PainelApiService,
    ) { }

    ngOnInit() {
        this.test_points = [
            {
                x: 1,
                y: 1,
                color: '#ff0000',
            },
            {
                x: 2,
                y: 2,
                color: '#0000ff',
            }
        ];
        this.test_rectangles = [
            {
                x1: 1,
                x2: 5,
                y1: 1,
                y2: 5,
                selected: true,
            },
            {
                x1: 2,
                x2: 4,
                y1: 2,
                y2: 4,
                selected: true,
            },
            {
                x1: 5,
                x2: 10,
                y1: 5,
                y2: 10,
                selected: false,
            },
            {
                x1: 6,
                x2: 11,
                y1: 6,
                y2: 11,
                selected: false,
            },
        ];
    }

    public onTestCanvasClick(click: Click) {
        this.test_points.push({
            x: click.x,
            y: click.y,
            color: '#ffff00',
        });

        this.test_rectangles.push({
            x1: click.x,
            y1: click.y,
            x2: click.x + 2,
            y2: click.y + 2,
            selected: false,
        });

        console.log('updated');
    }
}
