<div class="ml mt mr">
    <h1 i18n>Upload de imagem para recurso</h1>
    <form class="form-section">
        <label>
            <h2 i18n>Tipo de recurso</h2>

            <select name="color_type" (change)="onChangeColorType($event)">
                <option value="mono">Monocromático</option>
                <option value="rgb">RGB</option>
            </select>
        </label>

        <app-file-input mode="arrayBuffer" (onChange)="onChangeImage($event)" />

        <label>
            <h2><span i18n>Largura</span>:</h2>
            <input
                name="width"
                type="number"
                [value]="upload.width"
                [max]="scaledMaxWidth"
                (change)="onChangeWidth($event)"
            />
        </label>

        <label>
            <h2><span i18n>Altura</span>:</h2>
            <input
                name="height"
                type="number"
                [value]="upload.height"
                [max]="scaledMaxHeight"
                (change)="onChangeHeight($event)"
            />
        </label>

        <label *ngIf="upload.color_type == 'mono'">
            <h2>
                <span i18n>Invertido</span>:
                <input
                    name="inverted"
                    type="checkbox"
                    [checked]="upload.inverted"
                    (change)="onChangeInverted($event)"
                />
                <span i18n>Sim</span>/<span i18n>Não</span>
            </h2>
        </label>

        <button
            id="salvar-recurso"
            class="btn btn-success"
            type="submit"
            name="button"
            (click)="submit()"
            i18n
        >
            Cadastrar recurso
        </button>
    </form>

    <ng-container *ngIf="recurso">
        <app-pixel-canvas
            [width]="recurso.largura"
            [height]="recurso.tamanho"
            [pontos]="recurso.pontos"
        />
    </ng-container>
</div>
