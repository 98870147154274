import { Component, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import Color from "colorjs.io";

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements AfterViewInit {
    public color: Color;
    public hue: number;

    public visible = false;

    @Input() set cor(value: string) {
        let cor = "#000000";
        if (value) {
            cor = value;
        }

        let new_color = (new Color(cor)).to('hsv');
        this.color = new_color;

        if (!this.hue || (this.color.s > 0 && this.color.v > 0)) {
            console.log("updating hue");
            this.hue = this.color.h;
        }

        if (this.viewInited) {
            this.update();
        }
    }

    @Output() atualizar$ = new EventEmitter();

    @ViewChild('valueSaturationCanvas') public valueSaturationCanvas: ElementRef;
    public valueSaturationCtx: CanvasRenderingContext2D;

    @ViewChild('hueCanvas') public hueCanvas: ElementRef;
    public hueCtx: CanvasRenderingContext2D;

    @ViewChild('valueSaturationPin') public valueSaturationPin: ElementRef;
    @ViewChild('huePin') public huePin: ElementRef;

    @ViewChild('colorPreview') public colorPreview: ElementRef;

    constructor() {
    }

    viewInited = false;

    async ngAfterViewInit() {
        this.valueSaturationCtx = this.valueSaturationCanvas.nativeElement.getContext('2d');
        this.hueCtx = this.hueCanvas.nativeElement.getContext('2d');

        this.setupEvents();

        this.update();
        this.updateRGB();

        this.viewInited = true;

        // Modo Gamer:
        // setInterval(() => {
        //     this.hue += 4;
        //     if (this.hue > 360) {
        //         this.hue = 0;
        //     }
        //     this.update();
        // }, 10);
    }

    update() {
        this.drawHueCanvas();
        this.updateHuePin();

        this.drawValueSaturationCanvas();
        this.updateSaturationValuePin();

        this.colorPreview.nativeElement.style.backgroundColor = this.color.display();

        this.atualizar$.emit(this.color.to('srgb').toString());
    }

    // Desenha o canvas da direita
    drawHueCanvas() {
        const cx = this.hueCtx;
        const width = cx.canvas.width;
        const height = cx.canvas.height;

        for (let i = 0; i < height; i++) {
            const hue = i * 360 / height;

            cx.fillStyle = `hsl(${hue}deg, 100%, 50%)`;
            cx.fillRect(0, i, width, 1);
        }
    }

    // Desenha o canvas da esquerda
    // Ele tem as seguintes cores nos cantos:
    // ↖️ Branco
    // ↗️ Cor selecionada no outro canvas
    // ↙️ Preto
    // ↘️ Preto
    //
    // O meio do canvas tem a interpolação dessas cores
    //
    // O código é um pouco estranho por questões de preformance
    drawValueSaturationCanvas() {
        const cx = this.valueSaturationCtx;
        const width = cx.canvas.width;
        const height = cx.canvas.height;
        const h = this.hue;

        // Definir a cor dos cantos
        const topLeft = new Color("hsv", [h, 0, 100]);
        const topRight = new Color("hsv", [h, 100, 100]);
        const bottom = new Color("hsv", [h, 0, 0]);

        // Gerar a interpolação das cores dos cantos
        const config = { outputSpace: "srgb", steps: height };
        const leftColors = topLeft.steps(bottom, config);
        const rightColors = topRight.steps(bottom, config);

        for (let line = 0; line < height; line++) {
            // Usamos um degradê para fazer a interpolação do inicio ao fim da linha
            // Assim só fazemos uma draw-call por linha
            const gradient = cx.createLinearGradient(0, line, width, line);
            gradient.addColorStop(0, leftColors[line].toString());
            gradient.addColorStop(1, rightColors[line].toString());

            cx.fillStyle = gradient;
            cx.fillRect(0, line, width, 1);
        }
    }

    updateSaturationValuePin() {
        const pin = this.valueSaturationPin.nativeElement;
        const canvas = this.valueSaturationCanvas.nativeElement;
        let rect = canvas.getBoundingClientRect();

        const saturation = this.color.s;
        const value = this.color.v;

        let pin_x = saturation / 100 * rect.height;
        let pin_y = (100 - value) / 100 * rect.height;

        pin.style.left = `${pin_x}px`;
        pin.style.top = `${pin_y}px`;
        pin.style.backgroundColor = this.color.display();
    }

    updateHuePin() {
        const pin = this.huePin.nativeElement;
        const canvas = this.hueCanvas.nativeElement;
        let rect = canvas.getBoundingClientRect();

        const hue = this.hue;

        let pin_y = hue / 360 * rect.height;

        pin.style.top = `${pin_y}px`;
        pin.style.backgroundColor = `hsl(${hue}deg, 100%, 50%)`;
    }

    isDraggingHue = false;
    isDraggingSaturationValue = false;

    setupEvents() {
        let hue = this.hueCtx.canvas;
        hue.addEventListener('mousedown', (event) => this.startDraggingHue(event));
        document.addEventListener('mousemove', (event) => this.handleUpdateHue(event));
        document.addEventListener('mouseup', () => this.stopDragging());

        let sv = this.valueSaturationCtx.canvas;
        sv.addEventListener('mousedown', (event) => this.startDraggingSaturationValue(event));
        document.addEventListener('mousemove', (event) => this.handleUpdateSaturationValue(event));
        document.addEventListener('mouseup', () => this.stopDragging());
    }

    stopDragging() {
        this.isDraggingHue = false;
        this.isDraggingSaturationValue = false;
    }

    startDraggingHue(event: MouseEvent) {
        this.isDraggingHue = true;
        this.handleUpdateHue(event);
    }

    startDraggingSaturationValue(event: MouseEvent) {
        this.isDraggingSaturationValue = true;
        this.handleUpdateSaturationValue(event);
    }

    handleUpdateHue(event: MouseEvent) {
        if (!this.isDraggingHue) return;

        const target = this.hueCanvas.nativeElement;

        let rect = target.getBoundingClientRect();
        let y = event.clientY - rect.top;

        let new_hue = y * 360 / rect.height;
        this.color.h = limit(new_hue, 0, 360);
        this.hue = this.color.h;

        this.update();
        this.updateRGB();
    }

    handleUpdateSaturationValue(event: MouseEvent) {
        if (!this.isDraggingSaturationValue) return;

        const target = this.valueSaturationCanvas.nativeElement;
        let rect = target.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;

        const s = x * 100 / rect.width;
        const v = (rect.height - y) * 100 / rect.height;

        this.color.h = this.hue;
        this.color.s = limit(s, 0, 100);
        this.color.v = limit(v, 0, 100);

        this.update();
        this.updateRGB();
    }

    @ViewChild('rInput') public rInput: ElementRef;
    @ViewChild('gInput') public gInput: ElementRef;
    @ViewChild('bInput') public bInput: ElementRef;

    handleRGBupdate() {
        for (let elem of [this.rInput, this.gInput, this.bInput,]) {
            let currentValue = elem.nativeElement.value;
            let newValue = limit(+currentValue, 0, 255);
            let isEmpty = currentValue.length == 0;
            if (isNaN(currentValue) || isEmpty) {
                newValue = 0;
            }
            if (currentValue != newValue.toString() || isEmpty) {
                elem.nativeElement.value = newValue;
            }
        }

        this.color.srgb.r = (+this.rInput.nativeElement.value / 255);
        this.color.srgb.g = (+this.gInput.nativeElement.value / 255);
        this.color.srgb.b = (+this.bInput.nativeElement.value / 255);

        this.update();
    }

    updateRGB() {
        this.rInput.nativeElement.value = Math.round(this.color.srgb.r * 255);
        this.gInput.nativeElement.value = Math.round(this.color.srgb.g * 255);
        this.bInput.nativeElement.value = Math.round(this.color.srgb.b * 255);
    }

    toggleVisibility() {
        this.visible = !this.visible;
    }
}

function limit(num: number, min: number, max: number) {
    return Math.max(min, Math.min(max, num));
}
